//CORRECCION DE CENTRADO CUANDO EL CONTENIDO NO OCUPA TODA LA PANTALLA
.flex-wrapper2 {
	min-height: 100vh; /* These two lines are counted as one :-)       */
	flex-direction: column;

	display: flex;
	align-items: center;
	justify-content: center;
}
//CSS MAPAS
.mapas {
	height: 100vh !important;
}

// @media (min-width: 1400px) {
// 	.mapas {
// 		height: 600px !important;
// 	}
// }

.search {
	position: absolute;
	top: 6.7rem;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 400px;
	z-index: 10;
}

.search input {
	padding: 0.5rem;
	font-size: 1.5rem;
	width: 100%;
	border-radius: 10px;
	outline-color: transparent;
	outline-style: none;
	border: none;
}

.locate {
	position: absolute;
	bottom: 20%;
	right: 0.7%;
	background: none;
	border: none;
	z-index: 10;
}
.locate img {
	width: 30px;
	cursor: pointer;
}
