html {
	font-size: 14px;
}
@media (min-width: 768px) {
	html {
		font-size: 16px;
	}
}

//BACKGROUNDDS DE LOS PLANES
.bgpricehogar {
	background: linear-gradient(#26202bcc, #26202bcc),
		url('./planhogar.jpg') no-repeat center center;

	background-size: cover;
}

.bgpriceultra {
	background: linear-gradient(#26202bcc, #26202bcc),
		url('./planultra.jpg') no-repeat center center;

	background-size: cover;
}

.bgpricecorp {
	background: linear-gradient(#353434cc, #000000cc),
		url(https://blog.printsome.es/wp-content/uploads/sites/3/eventos-corporativos-tendencias.jpg)
			no-repeat center center;

	background-size: cover;
}
//END OF BACKGROUNDS

.textoprice {
	color: white;
}

.container {
	max-width: 960px;
}

.pricing-header {
	max-width: 700px;
	color: whitesmoke;
}

.card-deck .card {
	min-width: 220px;
}

.flex-wrapper {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	justify-content: space-evenly;
}

.shadow-sm {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
		0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
		0 16px 16px rgba(0, 0, 0, 0.12) !important;
}

.textfull {
	opacity: 1 !important;
}

.card {
	background-color: rgba(255, 255, 255, 0.589) !important;
	color: black;
}

.customspan {
	background-color: rgba(145, 21, 21, 0.445) !important;
	color: white;
}
