//VARIABLE DE ANIMACION EN HOVER
$zoom: 1.3;

//PLACEHOLDER
.bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

// Custom Jumbtron
.shadowcus {
	box-shadow: inset 10px 10px 72px 8px rgba(0, 0, 0, 0.75);
}

//columnas que contienen a los iconos
.col-planes-iconos {
	border: 0px;
	background-color: lighten($color: #1c1c1c, $amount: 0);
}

//tansicion de animacion
.animate-transition {
	transition: transform 0.5s ease;
}

//iconos de los planes
.planes-iconos {
	border: 0px;
	padding-top: 40px;
	padding-bottom: 40px;
	color: $primary;
	transition: transform 0.5s ease;
	z-index: 999 !important;
	position: relative;
	text-decoration: none;

	> span {

		font-size: 50px !important;
	}

	&:hover {
		> h3 {
			transform: scale($zoom);
		  text-decoration: none;
		}

		> span {
			transform: scale($zoom);
		}
	}
}

//CUSTOM JUMBOTRON
.jumbotron {
	padding-top: 70px;
	padding-left: 30px;
	padding-right: 30px;

	margin: -2px 0px 0px 0px;
	background-color: lighten($color: #1c1c1c, $amount: 0);
	color: whitesmoke;
	border-radius: 0%;

	padding-bottom: 20px;
}

//CSS DE REACT PARTICLES
.particulas {
	position: absolute;
}

//clase para priorizar los botones por encima de los layers
.btn-mod {
	position: relative;
	z-index: 9999;
}

//CUSTOM ROWS
.row-header {
	/* arriba/abajo y izq/der */
	margin-top: 50px;
	margin-bottom: 50px;
	padding: 0px auto;
}

//row que contiene los iconos de los planes
.row-bar {
	margin-right: 0px !important;
	margin-left: 0px !important;
	z-index: 0 !important;
	padding-top: 0px;
}

.row-content {
	margin: 0px auto;
	padding: 50px 0px 50px 0px;
	border-bottom: 1px ridge;
	min-height: 400px;
}
//END CUSTOM ROWS

//css para priorizar el iframe sobre cualquier layer
.iframe {
	z-index: 99999999;
}

.bgCarrousel {
	background: linear-gradient(#ebe4a9ba, #26202bcc), no-repeat center center;

	background-size: cover;
}

.img-gradient:after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: inline-block;

	background: linear-gradient(
		to bottom,
		rgba(25, 25, 26, 0.5) 0%,
		rgba(24, 22, 21, 0.5) 100%
	); /* W3C */
}
.img-gradient img {
	display: block;
}
