//REDONDEO DE IMAGENES
.roundimg {
	border-radius: 10px;
}

//ESPPACIO INFERIOR DE PARRAFOS
p {
	margin-block-end: 0px;
}

.nomarginbottom {
	margin-block-end: 0px !important;
}

::-webkit-scrollbar {
	width: 0px; /* Remove scrollbar space */
	background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
	background: transparent;
}
/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
	margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
	bottom: 1rem;
	z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
	height: 37rem;
}

.carousel-item > img {
	position: absolute;
	top: 0;
	left: 0;
	min-width: 100%;
	height: 32rem;
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
	margin-bottom: 1.5rem;
	text-align: center;
}
.marketing h2 {
	font-weight: 400;
}
.marketing .col-lg-4 p {
	margin-right: 0.75rem;
	margin-left: 0.75rem;
}

/* Featurettes
------------------------- */

.featurette-divider {
	margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
	font-weight: 300;
	line-height: 1;
	letter-spacing: -0.05rem;
	margin-top: 0px;
	color: black;
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 768px) {
	.bd-placeholder-img-lg {
		font-size: 3.5rem;
	}
}

@media (min-width: 40em) {
	/* Bump up size of carousel content */
	.carousel-caption p {
		margin-bottom: 1.25rem;
		font-size: 1.25rem;
		line-height: 1.4;
	}

	.featurette-heading {
		font-size: 50px;
	}
}

@media (min-width: 62em) {
	.featurette-heading {
		margin-top: 0rem;
	}
}

@media (min-width: 320px) {
	.carousel-item {
		height: 18rem;
	}

	.carousel-item > img {
		height: 18rem;
	}
}

@media (min-width: 768px) {
	.carousel-item {
		height: 37rem;
	}

	.carousel-item > img {
		height: 37rem;
	}
}

@media (min-width: 1400px) {
	.carousel-item {
		height: 42rem;
	}

	.carousel-item > img {
		height: 42rem;
	}
}

//CSS SCROLL
.scrollTop {
	color: #8a9597;
	position: fixed;
	bottom: 20px;
	left: 90%;
	z-index: 1000;
	cursor: pointer;
	animation: fadeIn 0.3s;
	transition: opacity 0.4s;
	opacity: 0.5;
	font-size: xx-large;
}

.scrollTop:hover {
	opacity: 1;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.5;
	}
}

// FACEBOOK SDK

//end facebbok
