address {
	font-size: 80%;
	margin: 0px;
	color: white;
}

.color {
	color: white !important;
}

.footer {
	background-color: lighten($color: #1c1c1c, $amount: 0);
	margin: 0px auto;
	padding: 20px 0px 20px 0px;
}

.icon {
	color: white;
	transition: 0.3s;
}

.icon:hover {
	color: $primary;
}
