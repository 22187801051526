/* The following block can be included in a custom.scss */
@import './base/settings';

// Example usage:

// CUSTOM THEMES COLORS FOR BOOTSTRAP ELEMENTS
$theme-colors: (
	'primary': $primary,
	'secondary': $secondary,
	'dark': $dark,
	'white': white,
	'priceultra': rgba(145, 21, 21, 0.575),
	'pricehogar': rgba(160, 115, 17, 0.575),
);

//Modificaciones del NAVBAR
.navbar-brand {
	color: black !important;
	margin-right: 0rem !important;
	font-size: 0rem !important;
}
.nav-link {
	color: black !important;
}

.fixed-top-mod {
	position: fixed !important;
	top: 0;
	right: 0;
	left: 0;
	z-index: 9999999999;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
	outline: none;
	box-shadow: none;
}

//MODIFICACIONES DEL DROPDOWN
.dropdown-menu {
	min-width: -webkit-fill-available !important;
	padding: 0.5rem 1px !important;
	z-index: 99999999 !important;
	margin: 10px 0px 0px !important;
}

.dropdown-toggle {
	white-space: break-spaces !important;
}

//modificacion del dropdown
.dropdown-toggle::after {
	margin-left: 0.08rem !important;
}

//MODIFICACIONES DEL SPINNER
.spinner-grow {
	vertical-align: middle !important;
}

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
