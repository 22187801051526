.bgimgfutbol {
	background: linear-gradient(#8bc232ba, #26202bcc),
		url(https://www.monederosmart.com/wp-content/uploads/2020/03/Soccer-ball-0-71942922_m.jpg)
			no-repeat center center;

	background-size: cover;
}

.bgimgfamily {
	background: linear-gradient(#ebe4a9ba, #26202bcc),
		url(https://www.camping-villasol.com/wp-content/uploads/2020/05/peliculas-para-ver-en-familia-1280x720.jpg)
			no-repeat center center;

	background-size: cover;
}

.bgimgmovil {
	background: linear-gradient(#bbaa11ba, #26202bcc),
		url(https://s3.amazonaws.com/arc-wordpress-client-uploads/infobae-wp/wp-content/uploads/2017/02/20155916/Celular-cama-1920.jpg)
			no-repeat center center;

	background-size: cover;
}

.bgColor {
	background-color: #000000;
}

h2 {
	color: $white;
}

.scrolling-wrapper-flexbox {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	height: 150px;
	width: 100%;
	-webkit-overflow-scrolling: touch;

	.canales {
		flex: 0 0 auto;
		margin-right: 3px;
	}
	&::-webkit-scrollbar {
		display: none;
	}
}

.canales {
	display: flex;
	position: relative;

	width: 150px;
	height: 90px;
	justify-content: center;

	background: black;
	align-self: center;
	cursor: pointer;
	flex-flow: column;
	transition: transform 0.5s ease !important;

	&:hover {
		> img {
			transform: scale(1.1);
		}
		> i {
			transform: scale(1.1);
		}
	}

	> i {
		transition: transform 0.5s ease !important;
		align-self: center;
		font-size: 40px;
		position: absolute;
		color: black;
		z-index: 999;
	}

	> img {
		transition: transform 0.5s ease !important;

		border-radius: 10px;
		left: 50%;
		top: 50%;
		vertical-align: middle;

		margin-left: auto;
		margin-right: auto;
	}
}
