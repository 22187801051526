//Colors
$primary: #d58936;
$secondary: #274c77;
$light: #d58936;
$accent: #e2dfdc;
$dark: #3c1518;
$grey: #474646;
$grafito: rgba(102, 101, 101, 0.774);
$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;
